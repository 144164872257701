<template>
  <div class="courses-details">

    <breadcrumb
        :title="'Информация о вебинаров'"
        :subtitle="'Список сохранение вебинары'"
    />

    <div class="px-4">
      <b-container>
        <b-row>
          <b-col cols="12">
            <div style="height: inherit">

              <!-- Overlay -->
              <div class="body-content-overlay"/>

              <!-- Searchbar -->
              <div class="course-searchbar mt-1">
                <b-row>
                  <b-col cols="12" class="p-0">
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          v-model="search"
                          placeholder="Поиск вебинаров"
                          class="search-product"
                          @keyup="fetchShopProducts"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            icon="SearchIcon"
                            class="text-muted"
                        />
                      </b-input-group-append>
                    </b-input-group>
                  </b-col>
                </b-row>
              </div>

              <!-- Prodcuts -->
              <section class="mt-2">
                <b-row class="courses-student">
                  <b-col sm="12" md="6" lg="3" :col="true"
                         v-for="course in courses"
                         :key="course.id"
                         class="mb-1 pl-0"
                  >
                    <b-card
                        class="box-shadow-1 h-100"
                        :img-src="course.logo ? `https://edu.startupchoikhona.tj/backend/${course.logo}` : require('@/assets/images/blogs/noPhotoBlogs.jpeg')"
                        img-alt=""
                        img-top
                        :title="course.title"
                    >

                      <span class="play-course" @click="playWebinar(course.id)"></span>
                      <b-card-text>
                        <small>
                          <span>Ментор: </span>
                          <span>{{ course.instructor ? course.instructor.name : '' }}</span>
                        </small>
                      </b-card-text>
                      <b-card-text>
                        <small>
                          <span>Курс: </span>
                          <span>{{ course.course ? course.course.title : '' }}</span>
                        </small>
                      </b-card-text>
                      <b-card-text>
                        <small>
                          <span>Модуль: </span>
                          <span>{{ course.module }}</span>
                        </small>
                      </b-card-text>
                      <b-card-text class="d-flex align-items-center">
                  <span class="mr-2">
                    <span>
                      <feather-icon class="text-white mr-50" icon="CalendarIcon"/>
                    </span>
                    <span>{{course.date}}</span>
                  </span>
                        <span class="mr-2">
                    <span>
                      <feather-icon class="text-white mr-50" icon="ClockIcon"/>
                    </span>
                    <span>{{course.time}}</span>
                  </span>
                      </b-card-text>
                    </b-card>
                  </b-col>
                </b-row>

              </section>
              <!-- Pagination -->
              <section>
                <b-row>
                  <b-col cols="12">
                    <b-pagination
                        v-model="page"
                        :total-rows="totalProducts"
                        :per-page="perPage"
                        first-number
                        align="center"
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                        />
                      </template>
                    </b-pagination>
                  </b-col>
                </b-row>
              </section>
            </div>
          </b-col>
        </b-row>

      </b-container>
      <b-modal
          id="modal-lg"
          dialog-class="w-100 h-100"
          content-class="w-100 h-100"
          size="xl"
          centered
          ok-only
          @click="modalShow = !modalShow"
          ok-title="Закрыть"
          v-model="modalShow"
      >
        <iframe :src="playPath" frameborder="0" class="w-100 h-100"></iframe>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BModal,
  BContainer,
  BDropdown,
  BDropdownItem,
  BFormRadioGroup,
  BFormRadio,
  BRow,
  BCol,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BCard,
  BCardBody,
  BLink,
  BImg,
  BCardText,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import '@/assets/css/styleCoursesStudent.css'
import Breadcrumb from '@/views/landing/components/Breadcrumb.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BContainer,
    // BSV
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    router,
    Breadcrumb,
  },
  data() {
    return {
      page: 1,
      perPage: 15,
      search: '',
      courses: [],
      totalProducts: 0,
      modalShow: false,
      playPath: '',
    }
  },
  created() {
    this.fetchShopProducts()
  },
  methods: {
    playWebinar(idWebinar) {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/saved-webinars/${idWebinar}/play`, {})
        .then(response => {
          this.modalShow = true
          this.playPath = response.data.data
        }).catch(() => {
        })
    },
    fetchShopProducts() {
      this.$http.post('https://edu.startupchoikhona.tj/backend/api/website/saved-webinars', {
        search: this.search,
      })
        .then(response => {
          const { data } = response.data
          this.courses = data.items
          this.totalProducts = data.total_rows
        })
    },
  },
  setup() {

    return {}
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-course.scss";
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
</style>
